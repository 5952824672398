<template>
  <BaseList
    :list-query="materials"
    route="material"
    locale-section="pages.materials"
  />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "MaterialsList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      materials: gql`
        query materials {
          list: materials {
            id
            name: material
            material
            materialType {
              id
            }
            materialSubTypes {
              id
            }
          }
        }
      `
    };
  }
};
</script>
